<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header @change="search" />
    <!-- :::::: End Header Section ::::::  -->
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="page-breadcrumb__nav active">{{$route.params.name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
    <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- :::::: Start Main Container Wrapper :::::: -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row flex-column-reverse flex-lg-row">
                <!-- Start Leftside - Sidebar Widget -->
                <div class="col-lg-3">
                    <div class="sidebar">
                        <!-- Start Single Sidebar Widget - Filter [Catagory] -->
                        <div class="sidebar__widget">
                            <div class="sidebar__box">
                                <h5 class="sidebar__title">PRODUCT CATEGORIES</h5>
                            </div>
                            <ul class="sidebar__menu">
                                <li>
                                    <ul class="sidebar__menu-collapse">
                                        <!-- Start Single Menu Collapse List -->
                                        <li class="sidebar__menu-collapse-list" v-for="Category in allCategory" :key="Category.id">
                                            <div class="accordion">
                                                <a class="accordion__title collapsed" data-bs-toggle="collapse" data-bs-target="#men-fashion" aria-expanded="false" :class="selectCat==Category.id ? 'active' : ''" @click="selectCategory(Category.id)"> {{Category.heading}}  
                                                     <!-- <i class="far fa-chevron-down"></i> -->
                                                     </a>
                                            </div>
                                        </li> <!-- End Single Menu Collapse List -->
                                    </ul>
                                </li>
                            </ul>
                        </div> <!-- End SSingle Sidebar Widget - Filter [Catagory] -->

                        <!-- Start Single Sidebar Widget - Filter [Price] -->
                        <div class="sidebar__widget">
                            <div class="sidebar__box">
                                <h5 class="sidebar__title">FILTER BY Price (INR)</h5>
                            </div>
                            <div class="sidebar__price-filter my-3">
                                 <template>
                                        <div>
                                            <price-range-slider v-model="range" :trackHeight="0.5" />
                                        </div>
                                        <button type="button" @click="catData" class="btn btn--large  btn--black btn--black-hover-green font--bold text-uppercase m-tb-40">Filter</button>
                                    </template>
                            </div>
                        </div> <!-- Start Single Sidebar Widget - Filter [Price] -->
                    </div>
                </div> <!-- End Left Sidebar Widget -->
                <!-- Start Rightside - Product Type View -->
                <div class="col-lg-9">
                    <div class="sort-box" v-if="ProductsCount>0">
                        <div class="sort-box-item">
                            <span>Showing 1 - {{ProductsCount}} Of {{ProductsCount}} result</span>
                        </div>
                    </div> <!-- ::::::  Start Sort Box Section  ::::::  -->

                    <div class="product-tab-area">
                        <div class="tab-content tab-animate-zoom">
                            <div class="tab-pane show active shop-grid" id="sort-grid">
                                <div class="row">
                                    <div class="col-md-4 col-sm-6 col-12" v-for="Products in allProducts" :key="Products.id">
                                        <!-- Start Single Default Product -->
                                        <div class="product__box product__default--single text-center">
                                            <!-- Start Product Image -->
                                            <div class="product__img-box  pos-relative">
                                                <router-link :to="{ name: 'productDetail', params:{id:Products.id}}" class="product__img--link"> 
                                                    <img class="product__img img-fluid p-img-css" :src="$root.URL_ROOT+'uploads/Product/'+Products.front_image" alt="">
                                                </router-link>
                                                <!-- Start Product Action Link-->
                                                <ul class="product__action--link pos-absolute">
                                                    <li><a @click="add_to_cart(Products.id,Products.name,'1',Products.selling_cost)" data-bs-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                                    <li><a @click="whishList(Products.id)"><i class="icon-heart"></i></a></li>
                                                </ul> <!-- End Product Action Link -->
                                            </div> <!-- End Product Image -->
                                            <!-- Start Product Content -->
                                            <div class="product__content m-t-20">
                                                <!-- <ul class="product__review">
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--blank"><i class="icon-star"></i></li>
                                                </ul> -->
                                                <router-link :to="{ name: 'productDetail', params:{id:Products.id}}" class="product__link"> {{Products.name}} </router-link>
                                                <div class="product__price m-t-5">
                                                    <span class="product__price">INR {{Products.selling_cost}} <del>INR {{Products.purchese_cost}}</del></span>
                                                </div>
                                            </div> <!-- End Product Content -->
                                        </div> <!-- End Single Default Product -->
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane shop-list" id="sort-list">
                                <div class="row">
                                    <!-- Start Single List Product -->
                                    <div class="col-12">
                                        <div class="product__box product__box--list">
                                            <!-- Start Product Image -->
                                            <div class="product__img-box  pos-relative text-center">
                                                <a href="product-single-default.html" class="product__img--link">
                                                    <img class="product__img img-fluid" src="assets/img/product/size-normal/product-home-1-img-5.jpg" alt="">
                                                </a>
                                                <!-- Start Procuct Label -->
                                                <span class="product__label product__label--sale-dis">-31%</span>
                                                <!-- End Procuct Label -->
                                            </div> <!-- End Product Image -->
                                            <!-- Start Product Content -->
                                            <div class="product__content">
                                                <ul class="product__review">
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--blank"><i class="icon-star"></i></li>
                                                </ul>
                                                <a href="product-single-default.html" class="product__link">
                                                    <h5 class="font--regular">Best Red Meat</h5>
                                                </a>
                                                <div class="product__price m-t-5">
                                                    <span class="product__price">$55.00 <del>$80.00</del></span>
                                                </div>
                                                <div class="product__desc">
                                                    <p>
                                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                                                    </p>
                                                </div>
                                                <!-- Start Product Action Link-->
                                                <ul class="product__action--link-list m-t-30">
                                                    <li><a href="#modalAddCart" data-bs-toggle="modal" class="btn--black btn--black-hover-green">Add to cart</a></li>
                                                    <li><a href="compare.html"><i class="icon-sliders"></i></a></li>
                                                    <li><a href="wishlist.html"><i class="icon-heart"></i></a></li>
                                                </ul> <!-- End Product Action Link -->
                                            </div> <!-- End Product Content -->
                                        </div>
                                    </div> <!-- End Single List Product -->
                                    <!-- Start Single List Product -->
                                    <div class="col-12">
                                        <!-- Start Single List Product -->
                                        <div class="product__box product__box--list">
                                            <!-- Start Product Image -->
                                            <div class="product__img-box  pos-relative text-center">
                                                <a href="product-single-default.html" class="product__img--link">
                                                    <img class="product__img img-fluid" src="assets/img/product/size-normal/product-home-1-img-8.jpg" alt="">
                                                </a>
                                                <!-- Start Procuct Label -->
                                                <span class="product__label product__label--sale-dis">-35%</span>
                                                <!-- End Procuct Label -->
                                                <!-- Start Product Countdown -->
                                                <div class="product__counter-box">
                                                    <div class="product__counter-item" data-countdown="2021/03/01"></div>
                                                </div> <!-- End Product Countdown -->
                                            </div> <!-- End Product Image -->
                                            <!-- Start Product Content -->
                                            <div class="product__content">
                                                <ul class="product__review">
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--blank"><i class="icon-star"></i></li>
                                                </ul>
                                                <a href="product-single-default.html" class="product__link">
                                                    <h5 class="font--regular">Best Ripe Grapes</h5>
                                                </a>
                                                <div class="product__price m-t-5">
                                                    <span class="product__price">$39.00 <del>$60.00</del></span>
                                                </div>
                                                <div class="product__desc">
                                                    <p>
                                                        On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will
                                                    </p>
                                                </div>
                                                <!-- Start Product Action Link-->
                                                <ul class="product__action--link-list m-t-30">
                                                    <li><a href="#modalAddCart" data-bs-toggle="modal" class="btn--black btn--black-hover-green">Add to cart</a></li>
                                                    <li><a href="compare.html"><i class="icon-sliders"></i></a></li>
                                                    <li><a href="wishlist.html"><i class="icon-heart"></i></a></li>
                                                </ul> <!-- End Product Action Link -->
                                            </div> <!-- End Product Content -->
                                        </div> <!-- End Single List Product -->
                                    </div> <!-- End Single List Product -->
                                    <!-- Start Single List Product -->
                                    <div class="col-12">
                                        <!-- Start Single List Product -->
                                        <div class="product__box product__box--list">
                                            <!-- Start Product Image -->
                                            <div class="product__img-box  pos-relative text-center">
                                                <a href="product-single-default.html" class="product__img--link">
                                                    <img class="product__img img-fluid" src="assets/img/product/size-normal/product-home-1-img-4.jpg" alt="">
                                                </a>
                                            </div> <!-- End Product Image -->
                                            <!-- Start Product Content -->
                                            <div class="product__content">
                                                <ul class="product__review">
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--blank"><i class="icon-star"></i></li>
                                                </ul>
                                                <a href="product-single-default.html" class="product__link">
                                                    <h5 class="font--regular">Cabbage Vegetables</h5>
                                                </a>
                                                <div class="product__price m-t-5">
                                                    <span class="product__price">$50.00</span>
                                                </div>
                                                <div class="product__desc">
                                                    <p>
                                                        On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will
                                                    </p>
                                                </div>
                                                <!-- Start Product Action Link-->
                                                <ul class="product__action--link-list m-t-30">
                                                    <li><a href="#modalAddCart" data-bs-toggle="modal" class="btn--black btn--black-hover-green">Add to cart</a></li>
                                                    <li><a href="compare.html"><i class="icon-sliders"></i></a></li>
                                                    <li><a href="wishlist.html"><i class="icon-heart"></i></a></li>
                                                </ul> <!-- End Product Action Link -->
                                            </div> <!-- End Product Content -->
                                        </div> <!-- End Single List Product -->
                                    </div> <!-- End Single List Product -->
                                    <!-- Start Single List Product -->
                                    <div class="col-12">
                                        <!-- Start Single List Product -->
                                        <div class="product__box product__box--list">
                                            <!-- Start Product Image -->
                                            <div class="product__img-box  pos-relative text-center">
                                                <a href="product-single-default.html" class="product__img--link">
                                                    <img class="product__img img-fluid" src="assets/img/product/size-normal/product-home-1-img-9.jpg" alt="">
                                                </a>
                                                <!-- Start Procuct Label -->
                                                <span class="product__label product__label--sale-out">Soldout</span>
                                                <!-- End Procuct Label -->
                                            </div> <!-- End Product Image -->
                                            <!-- Start Product Content -->
                                            <div class="product__content">
                                                <ul class="product__review">
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--fill"><i class="icon-star"></i></li>
                                                    <li class="product__review--blank"><i class="icon-star"></i></li>
                                                </ul>
                                                <a href="product-single-default.html" class="product__link">
                                                    <h5 class="font--regular">Cow Fresh Milk</h5>
                                                </a>
                                                <div class="product__price m-t-5">
                                                    <span class="product__price">$50.00</span>
                                                </div>
                                                <div class="product__desc">
                                                    <p>
                                                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.
                                                    </p>
                                                </div>
                                                <!-- Start Product Action Link-->
                                                <ul class="product__action--link-list m-t-30">
                                                    <li><a href="#modalAddCart" data-bs-toggle="modal" class="btn--black btn--black-hover-green">Add to cart</a></li>
                                                    <li><a href="compare.html"><i class="icon-sliders"></i></a></li>
                                                    <li><a href="wishlist.html"><i class="icon-heart"></i></a></li>
                                                </ul> <!-- End Product Action Link -->
                                            </div> <!-- End Product Content -->
                                        </div> <!-- End Single List Product -->
                                    </div> <!-- End Single List Product -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="page-pagination">
                        <ul class="page-pagination__list">
                            <li class="page-pagination__item"><a class="page-pagination__link" href="#">Prev</a>
                            <li class="page-pagination__item"><a class="page-pagination__link active" href="#">1</a></li>
                            <!-- <li class="page-pagination__item"><a class="page-pagination__link" href="#">2</a></li> -->
                            <li class="page-pagination__item"><a class="page-pagination__link" href="#">Next</a>
                            </li>
                        </ul>
                    </div>
                </div> <!-- Start Rightside - Product Type View -->
            </div>
        </div>
    </main> <!-- :::::: End MainContainer Wrapper :::::: -->
    <!-- ::::::  Start  Footer ::::::  -->
    <Footer />
    <!-- ::::::  End  Footer ::::::  -->
</body>
</template>

<script>
import axios from "axios";
import PriceRangeSlider from "@/components/PriceRangeSlider";
import Header from "@/components/navbar";
import Footer from "@/components/footer";
export default {
    components: {
        Header,
        Footer,
        PriceRangeSlider
    },
    data() {
        return {
            isFilter: false,
            selectCat: 0,
            page: 2,
            isAct: 0,
            search_key: '',
            ProductsCount: 0,
            isHas: false,
            filterData: false,
            productCount: 0,
            range: {
                minValue: 0,
                maxValue: 1000
            },
            ProdNoRecordFound: false,
            NoRecordFound: false,
            limit: 0,
            startval: null,
            isCheckeds: false,
            colorVal: [],
            colors: 0,
            setSelected: 0,
            sizes: 0,
            allCategory: [],
            allProducts: [],
            id: this.$route.params.id,
        };
    },
    mounted() {
        this.searchingVar = JSON.parse(localStorage.getItem("searchingVar"));
        this.goToTop();
        this.categories();
         if (this.$route.query.searchkey) {
            this.search()
        } else {
            this.catData(this.$route.params.id);
        }
       

    },
    watch: {
        '$route.params.id': function (newVal, oldVal) {
            // console.log(newVal,oldVal)
            this.catData(newVal);
        },
        'selectCat': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.isFilter = true
                this.catData();
            }
        },
        // 'search_key': function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         this.isFilter = true
        //         // this.catData();
        //     }
        // },
         '$route.query.searchkey': function () {
            if (this.$route.query.searchkey != '') {
                this.search()
            } else {
                this.catData(this.$route.params.id);
            }
        },
        'range': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.isFilter = true
                // this.catData();
            }
        },
    },
    methods: {
        selectCategory(id) {
            this.selectCat = id
        },
        limitSet(id) {
            this.limit = this.limit + id;
            this.isAct = id;
            this.catData();
        },
        w3_open() {
            document.getElementById("mySidebar").style.display = "block";
        },

        w3_close() {
            document.getElementById("mySidebar").style.display = "none";
        },
        search(id) {
            var self = this;
            var searchKey = this.$route.query.searchkey
            if (searchKey == '') {
                self.$toasted.global.error({
                    message: 'Please and product or category name'
                });
            } else {
                axios
                    .post(this.$root.URL_ROOT + "api.php", {
                        type: 'searchProduct',
                        cid: self.id,
                        search_key: searchKey
                    })
                    .then(function (response) {
                        var data = response.data;
                        if (data.status == "success") {
                            self.allProducts = data.data;
                            self.ProductsCount = data.count;
                        } else {
                            self.NoRecordFound = true;
                            self.ProductsCount = data.count;
                        }
                    });
            }

        },
        categories() {
            var ev = this;
            axios
                .post(this.$root.URL_ROOT + "api.php", {
                    type: 'getCategory'
                })
                .then(function (response) {
                    var data = response.data
                    if (data.status == 'success') {
                        ev.allCategory = data.category;
                    }
                });
        },
        catData(id) {
            this.id = id
            const axios = require("axios");
            var self = this;
            if (this.isFilter != false) {
                axios.post(this.$root.URL_ROOT + "api.php", {
                        type: 'priceSearchProduct',
                        min: self.range.minValue,
                        max: self.range.maxValue,
                        cid: self.selectCat,
                        search_key: self.search_key
                    })
                    .then(function (response) {
                        var data = response.data;
                        console.log('FilterApi', data);
                        if (data.status == "success") {
                            self.allProducts = data.data;
                            self.ProductsCount = data.count;
                            self.searchshow = true;
                        } else {
                            self.NoRecordFound = true;
                            self.ProductsCount = data.count;
                        }
                    });
            } else {
                axios
                    .post(this.$root.URL_ROOT + "api.php", {
                        type: 'categoryProduct',
                        id: id,
                        limit: this.limit,
                    })
                    .then(function (response) {
                        var data = response.data;
                        console.log('CategoryApi', data);
                        if (data.status == "success") {
                            self.allProducts = data.product;
                            self.ProductsCount = data.count;
                            self.productCount = parseInt(self.allProducts.count)
                        } else {

                        }
                    });
            }
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

    },
};
</script>

<style scoped>
input {
    box-shadow: 0;
    outline: 0;
}

.price-slider {
    width: 300px;
    margin: 20px 0;
    text-align: center;
    position: relative;
    height: 9em;
    display: flex;
    flex-direction: column;
}

.price-slider>span {
    display: block;
    margin-bottom: 24px;
}

.price-slider>div {
    position: relative;
    margin-top: 10px;
}

input.start {
    bottom: 10px;
}

input.end {
    top: 20px;
}

.price-slider svg,
.price-slider input[type="range"] {
    position: absolute;
    left: 0;
}

input[type="number"] {
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1.6em;
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
    border: 2px solid #e60023;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #1da1f2;
}

input[type="range"]:focus::-ms-fill-lower {
    background: #1da1f2;
}

input[type="range"]:focus::-ms-fill-upper {
    background: #1da1f2;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: #1da1f2;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type="range"]::-ms-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #1da1f2;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
}

#mySidebar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #fff;
    overflow: auto;
}

#mySidebar .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
}

.color-div {
    width: 35px;
    height: 35px;
    border: 1px solid #d6d6d6;
}

.allp {
    display: block !important;
}

.filp {
    display: block !important;
}

.hidethis {
    display: none;
}

.checkstyle {
    margin: 20px 0 0 20px;
    visibility: visible;
}

.product-list .product-block .desc {
    height: 150px;
    overflow: hidden;
}

.size-css {
    margin: 0 50px 0 0 !important;
}

.bclass {
    border: 1px solid #000;
}

.checkbox input[type="checkbox"] {
    float: right;
    margin: 15px -30px 0 0;
    visibility: hidden;
}

.checkeds {
    background: #000 !important;
    color: #fff !important;
}

button>label>input[type="checkbox"]:checked {
    background: #000 !important;
    color: #fff !important;
}

.bclass {
    border: 1px solid #000;
}

.boxes {
    margin: auto;
    padding: 50px;
    background: #484848;
}

/*Checkboxes styles*/
input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px "Open Sans", Arial, sans-serif;
    color: #ddd;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type="checkbox"]+label:last-child {
    margin-bottom: 0;
}

input[type="checkbox"]+label:before {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    border: 2px solid #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked+label:before {
    width: 10px;
    top: -5px;
    left: 20px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.app-content {
    padding: 40px 15px;
}

.radio,
.checkbox {
    display: inline-flex;
    min-height: 20px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.c-label {
    color: #fff !important;
    padding: 12px;
    text-transform: capitalize;
    font-weight: bold !important;
}

.c-labels {
    color: #000 !important;
    padding: 12px;
    text-transform: capitalize;
    font-weight: bold !important;
}

.c-labels:hover {
    background: #000 !important;
    color: #fff !important;
}

input[type="checkbox"]+.c-labels:before {
    content: "";
    display: block;
    width: 70px;
    height: 45px;
    border: 2px solid #adadad;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    transition: all 0.12s, border-color 0.08s;
}

.searchshow {
    display: block !important;
}

.price-filter {
    margin: 20px 0;
}

.price-filter button {
    text-align: right;
}

.pagination {
    display: inline-block;
    float: right;
}

.pagination a {
    color: black;
    float: left;
    padding: 4px 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
}

.pagination a.active {
    background-color: #b6262f;
    color: white;
    border: 1px solid #b6262f;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

#breadcrumb_wrapper {
    float: right;
    text-align: right;
    padding: 0;
    margin: 0 0 20px 0;
    right: 0;
    width: 74%;
    position: relative;
}

#search_filters {
    margin: 20px 0;
    border: 5px solid #f1f1f1;
    border-radius: 10px;
}

#search_filters .filter-heading {
    background: #f1f1f1;
    padding: 15px;
}

a.product-category.active {
    color: #fdb306;
}

@media only screen and (max-width: 600px) {

    .mobile-margin {
        margin-top: 30px;
    }
}
</style>
